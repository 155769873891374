import { Component } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ALET - administrace';

  private subscriptions: Subscription[] = [];

  constructor(
    private _authService: AuthService,
    private router: Router,
  ) { }

  authService = this._authService;

  logout(): void {
    this.subscriptions.push(this._authService.logout().subscribe(
      () => {
        this.router.navigate(['prihlaseni']);
      },
    ));
  }

}
