import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, Subscription, Subscriber } from 'rxjs';
import { ApiClient } from '@helpers/api-client';
import { TokenStorageService } from '@services/token-storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private eventObservable: Observable<void> | null = null;
  private observers: Subscriber<void>[] = [];

  constructor(
    private tokenStorageService: TokenStorageService,
    private api: ApiClient,
  ) { }

  get isAuthenticated(): boolean {
    if (this.tokenStorageService.token) {
      return true;
    }

    return false;
  }

  get events(): Observable<void> {
    if (this.eventObservable === null) {
      this.eventObservable = new Observable<void>(
        (observer) => {
          this.observers.push(observer);

          return () => {
            this.observers = this.observers.filter(
              item => item !== observer
            );
          };
        }
      );
    }

    return this.eventObservable;
  }

  get token(): string | null {
    if (!this.isAuthenticated) {
      return null;
    }

    return this.tokenStorageService.token;
  }

  authenticate(username: string, password: string): Observable<string> {
    var observable = new Observable<string>(
      observer => {

        var subscription: Subscription = this.api.post<string>(
          environment.api + '/users/authenticate',
          {
            username: username,
            password: password,
          },
        ).subscribe(
          (token: string) => {
            this.tokenStorageService.token = token;
            observer.next(token);
          },
        );

        return () => {
          subscription.unsubscribe();
        };
      },
    );

    return observable;
  }

  logout(): Observable<void> {
    var observable = new Observable<void>(
      observer => {
        var subscription: Subscription = this.api.post<void>(
          environment.api + '/users/logout',
          undefined,
        ).subscribe(
          () => {
            this.tokenStorageService.delete();
            observer.next();
          },
          () => {
            this.tokenStorageService.delete();
            observer.next();
          },
        );

        return () => {
          subscription.unsubscribe();
        };
      },
    );

    return observable;
  }

}
