import { HttpClient, HttpParams, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '@services/token-storage.service';

class HttpUrlEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApiClient {
    constructor(
        private http: HttpClient,
        private tokenStorageService: TokenStorageService,
    ) { }

    get<T>(url: string, params?: { [param: string]: string | string[] }): Observable<T> {
        var headers: { [key: string]: string } = {
            'Accept': 'application/json',
        }

        if (this.tokenStorageService.token !== undefined && this.tokenStorageService.token !== null) {
            headers['Authorization'] = 'Token ' + this.tokenStorageService.token;
        }

        return this.http.get<T>(
            url,
            {
                headers: headers,
                params: new HttpParams({
                    fromObject: params,
                    encoder: new HttpUrlEncoder(),
                }),
            },
        );
    }

    post<T>(url: string, body: any): Observable<T> {
        var headers: { [key: string]: string } = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }

        if (this.tokenStorageService.token !== undefined && this.tokenStorageService.token !== null) {
            headers['Authorization'] = 'Token ' + this.tokenStorageService.token;
        }

        return this.http.post<T>(url, body, {
            headers: headers,
        });
    }

    patch<T>(url: string, body: any): Observable<T> {
        var headers: { [key: string]: string } = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }

        if (this.tokenStorageService.token !== undefined && this.tokenStorageService.token !== null) {
            headers['Authorization'] = 'Token ' + this.tokenStorageService.token;
        }

        return this.http.patch<T>(url, body, {
            headers: headers,
        });
    }

    put<T>(url: string, body: any): Observable<T> {
        var headers: { [key: string]: string } = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }

        if (this.tokenStorageService.token !== undefined && this.tokenStorageService.token !== null) {
            headers['Authorization'] = 'Token ' + this.tokenStorageService.token;
        }

        return this.http.put<T>(url, body, {
            headers: headers,
        });
    }

    postForm<T>(url: string, body: any): Observable<T> {
        var headers: { [key: string]: string } = {
            'Accept': 'application/json',
        }

        if (this.tokenStorageService.token !== undefined && this.tokenStorageService.token !== null) {
            headers['Authorization'] = 'Token ' + this.tokenStorageService.token;
        }

        return this.http.post<T>(url, body, {
            headers: headers,
        });
    }

    postFormWithProgress<T>(url: string, body: any): Observable<HttpEvent<T>> {
        var headers: { [key: string]: string } = {
            'Accept': 'application/json',
        }

        if (this.tokenStorageService.token !== undefined && this.tokenStorageService.token !== null) {
            headers['Authorization'] = 'Token ' + this.tokenStorageService.token;
        }

        return this.http.post<T>(url, body, {
            headers: headers,
            reportProgress: true,
            observe: 'events',
        });
    }

    putForm<T>(url: string, body: any): Observable<T> {
        var headers: { [key: string]: string } = {
            'Accept': 'application/json',
        }

        if (this.tokenStorageService.token !== undefined && this.tokenStorageService.token !== null) {
            headers['Authorization'] = 'Token ' + this.tokenStorageService.token;
        }

        return this.http.put<T>(url, body, {
            headers: headers,
        });
    }

}
