import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                () => {
                },
                (error) => {
                    if (!error.url.endsWith('/logout')) {
                        if (error instanceof HttpErrorResponse && error.status === 401) {
                            this.authService.logout().subscribe();
                        }
                    } else {
                        this.router.navigate(['prihlaseni']);
                    }
                },
            ),
        );
    }

}
