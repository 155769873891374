import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(
    private storageService: StorageService,
  ) { }

  set token(value: string | null) {
    if (value !== null) {
      this.storageService.setValue('token', value);
    } else {
      this.delete();
    }
  }

  get token(): string | null {
    return this.storageService.getValue('token');
  }

  delete() {
    this.storageService.removeKey('token');
  }
}
