<div class="container">
  <div class="mx-auto mt-5 col-md-6 col-sm-12">
    <div class="card">
      <form>
        <div class="card-header py-3">
          <h2>Přihlášení</h2>
        </div>
        <div class="card-body">
          <div class="mb-3">
            <label for="login">Login</label>
            <input type="text" name="username" class="form-control" autocomplete="off" placeholder="Zadejte uživatelské jméno" [(ngModel)]="username">
          </div>
          <div class="mb-3">
            <label for="password">Heslo</label>
            <input type="password" name="password" class="form-control" autocomplete="off" placeholder="Zadejte heslo" [(ngModel)]="password">
          </div>
          <button type="submit" (click)="login()" class="btn btn-primary w-100 text-uppercase">Přihlásit se</button>
        </div>
      </form>
    </div>
  </div>
</div>
