import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements OnDestroy {
  private subscription: Subscription | null = null;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnDestroy() {
    if (this.subscription !== null) {
      console.log('unsubscribing');
      this.subscription.unsubscribe();
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.authService.isAuthenticated) {
      this.router.navigate(['prihlaseni']);

      return of(false);
    }

    return of(true);
  }

}
