<div class="container-fluid">
  <div class="row" style="background-color: #bbddff" *ngIf="authService.isAuthenticated">
    <div class="col-1">
      <h1 class="text-center">ALET</h1>
    </div>
    <div class="col-1"><a [routerLink]="['nastaveni', 'produkty']">Nastavení</a></div>
    <div class="col-1"><a [routerLink]="['objednavky']">Objednávky</a></div>
    <div class="col-1"><a [routerLink]="['ucetni']">Účetní</a></div>
    <div class="col-1"><a [routerLink]="['zakaznici']">Zákazníci</a></div>
    <div class="col-1"><a [routerLink]="['markety']">Markety</a></div>
    <div class="col-1">Změna hesla</div>
    <div class="col-1"><a href="javascript:void(0)" (click)="logout()">Odhlásit</a></div>
  </div>
  <div style="padding:10px">
    <router-outlet></router-outlet>
  </div>
</div>
